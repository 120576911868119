<template>
	<div>
		<v-row v-if="selected.length === 1" class="justify-start ml-2">
			<v-col cols="1">
				<v-btn class="mr-2" small fab dark color="accent" @click="editItem">
					<v-icon dark>mdi-pencil</v-icon>
				</v-btn>
			</v-col>
			<div cols="1">
				<v-btn class="ml-4" small fab dark color="accent" @click="deleteItem">
					<v-icon dark>mdi-trash-can-outline</v-icon>
				</v-btn>
			</div>
		</v-row>
		<v-data-table
			v-model="selected"
			:headers="headerTitles"
			:items="listData"
			:search="searchData"
			item-key="id"
			show-select
			:single-select="singleSelect"
			class="elevation-1"
			@item-selected="objSelected"
		>
			<!-- User Is Active -->
			<template v-slot:item.user_obj.is_active="{ item }">
				<div class="flex justify-center">
					<v-chip
						label
						:color="item.user_obj.is_active === true ? 'success': 'error'"
					>
						{{ item.user_obj.is_active === true ? 'True': 'False' }}
					</v-chip>
				</div>
			</template>
			<template v-slot:item.is_active="{ item }">
				<div class="flex justify-center">
					<v-chip
						label
						:color="item.is_active === true ? 'success': 'error'"
					>
						{{ item.is_active === true ? 'True': 'False'}}
					</v-chip>
				</div>
			</template>
			<!-- Last Login for User -->
			<template v-slot:item.user_obj.last_login="{ item }">
				<div class="flex justify-center">
					{{ item.user_obj.last_login | moment("MM/DD/YY hh:mm:ss") }}
				</div>
			</template>
			<!-- Profile Images -->
			<template v-slot:item.profile_img="{ item }">
				<div v-if="item.profile_img" class="flex justify-center">
					<v-img
						max-height="30"
						max-width="30"
						:src="item.profile_img">
					</v-img>
				</div>
				<div v-if="!item.profile_img" class="flex justify-center">
					<v-img
						max-height="30"
						max-width="30"
						src="@/assets/static/UserProfileGrey170x170.png">
					</v-img>
				</div>
			</template>

			<!-- Company Phone Numbers -->
			<template v-slot:item.primary_phone="{ item }">
				<div class="flex justify-center" v-if="item.primary_phone">
					{{ item.primary_phone | phoneNumber }}
				</div>
			</template>
			<template v-slot:item.user_obj.mobile_phone="{ item }">
				<div class="flex justify-center">
					{{ item.user_obj.mobile_phone | phoneNumber }}
				</div>
			</template>
			<!-- Buttons and Actions -->
			<template v-slot:item.actions="{ item }">
				<v-row justify="space-between">
					<v-col cols="2">
						<v-btn>
							<v-icon
								small
								class="my-2"
								@click="editItem(item)"
							>
								mdi-pencil
							</v-icon>
						</v-btn>
					</v-col>

					<v-col cols="2">
						<v-btn>
							<v-icon
								small
								class="my-2"
								@click="deleteItem(item)"
							>
								mdi-delete
							</v-icon>
						</v-btn>
					</v-col>

				</v-row>
			
			</template>
		</v-data-table>
		<!-- Dialogs -->
		<template>
	
			<v-dialog v-model="dialogDelete" max-width="500px">
				<v-card>
					<v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>

		<!-- <template>
			Value: {{ Object.values(headerTitles)[Object.keys(headerTitles).length-1].value === 'user_obj.is_active' }}
		</template> -->
	</div>
</template>

<script>
"use-strict"
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'dataTableComponent',
  components: {

  },
  mixins: [

  ],
  props: {
		headerTitles: {
			type: Array,
			required: true
		},
		listData: {
			type: Array,
			required: true
		},
		searchData: {
			type: String,
			required: false
		},
	
  },
  data () {
    return {
			// Main Data
			dialogDelete: false,

			//Vuetify Datatable
			singleSelect: true,
			selected: [],
		
    }
  },
  methods: {
    testButton() {
			
		},
		objSelected(evt) {
			console.log("evt", evt);
			if(evt.value == false) {
				// Clear table
				this.$emit("clearData", null)
			} else {
				// Populate Table
			}
		},
		editItem (item) {
			console.log('editItem this.singleSelect',this.selected)
			this.$emit("updateItem", this.selected[0])
		},

		deleteItem (item) {
			console.log('deleteItem')
			this.dialogDelete = true

			
		},
		deleteItemConfirm() {
			console.log('deleteItemConfirm')
			//Make Call to delete item

			this.dialogDelete = false
			this.$emit("deleteItem", this.selected[0])
		},
		clearItem() {
			this.selected = []
		}

  },
  computed: {
    ...mapState([]),
		...mapGetters([]),


	},
	created() {

	},
  mounted () {


		

		
	},

}
</script>

<style scoped lang="scss">

</style>
